<template>
  <!--  普通仓库，和收费仓库  -->
  <div class="whoseHousPage" v-if="showNolist == false">
    <div>
      <headBackBox iCustomizdde="true" :icon="true">
        <template v-slot:left-icon>
          <van-icon name="arrow-left" @click="$router.go(-1)" />
        </template>
        <template #default>
          <span>
            {{ $fanyi("仓库") }}
          </span>
        </template>
      </headBackBox>
      <van-tabs
        v-model:active="swiperModel"
        @click-tab="clicktabs"
        title-inactive-color="#000000"
        line-width="30px"
        animated
        title-active-color="#B4272B"
      >
        <!-- 免费仓库列表 -->
        <van-tab
          :title="
            $fanyi('普通仓库') + `(${findNum(stockTableNum, '普通仓库')})`
          "
        >
          <div class="tablePosiBox">
            <van-pull-refresh
              v-model="gratisTablerefreshing"
              @refresh="onRefresh"
              head-height="100"
              :loosing-text="$fanyi('释放即可刷新...')"
              :pulling-text="$fanyi('下拉刷新')"
              :loading-text="$fanyi('加载中')"
              :success-text="$fanyi('加载成功')"
            >
              <van-list
                v-model:loading="loading"
                :loading-text="$fanyi('加载中')"
                :finished="gratisTablefinished"
                :finished-text="$fanyi('')"
                @load="onLoad"
              >
                <ul class="gratisTableBox">
                  <li
                    v-for="(gratisItem, gratisIndex) in gratisTable"
                    :key="gratisIndex"
                    :class="{
                      openrowd: gratisItem.Collapse,
                    }"
                  >
                    <h2 @click="gratisItem.Collapse = !gratisItem.Collapse">
                      <!-- 如果商品的可提出数量不为0就可以选择,如果为0就不能选择并且提示客户   -->
                      <input
                        type="checkbox"
                        v-model="gratisItem.checked"
                        :checked="gratisItem.checked"
                        @click.stop
                        @change="shopAll(gratisIndex)"
                      />
                      <span
                        v-longpress="
                          () => {
                            copySn(gratisItem.order_sn);
                          }
                        "
                        >{{ $fanyi("订单号") }}：{{ gratisItem.order_sn }}</span
                      >
                      <van-icon
                        name="arrow-down"
                        :class="{ Collapse: gratisItem.Collapse }"
                      />
                    </h2>
                    <div
                      class="goodsList"
                      v-for="(
                        order_detailitem, order_detailindex
                      ) in gratisItem.order_detail"
                      :key="order_detailindex"
                    >
                      <div class="outfit">
                        <input
                          type="checkbox"
                          v-model="order_detailitem.checked"
                          @change="oddChoice(order_detailitem, gratisIndex)"
                        />
                        <span>番号：#{{ order_detailitem.sorting }}</span>
                      </div>
                      <div
                        class="productInformation"
                        @click="
                          $fun.toCommodityDetails(
                            order_detailitem.goods_id,
                            order_detailitem.from_platform
                          )
                        "
                      >
                        <van-image
                          lazy-load
                          class="productImg"
                          :src="order_detailitem.pic"
                        >
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                        </van-image>
                        <div class="infoBox">
                          <h3>{{ order_detailitem.goods_title }}</h3>
                          <div
                            class="detailBox"
                            @click.stop="openDetailBox(order_detailitem)"
                          >
                            <span
                              v-for="(
                                order_detaildetailitem, order_detaildetailindex
                              ) in order_detailitem.detail"
                              :key="order_detaildetailindex"
                            >
                              {{ order_detaildetailitem.value }};
                            </span>
                          </div>
                          <div class="priceBox">
                            <span class="redFont">
                              <span class="bigFont">
                                {{ $fun.ceil(order_detailitem.confirm_price) }}
                                {{ $fanyi("元") }}
                              </span>

                              <span class="smallFont">
                                ({{
                                  $fun.JPYNumSegmentation(
                                    $fun.roundNumber(
                                      order_detailitem.confirm_price *
                                        $store.state.exchangeRate
                                    )
                                  )
                                }}
                                円 )
                              </span>
                            </span>
                            <span class="grayFont"
                              >X {{ order_detailitem.confirm_num }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="numRow">
                        <div class="theNumberOfLibraries normalOpt greenOpt">
                          {{ $fanyi("发送可能数") }}:{{
                            order_detailitem.send_may_num
                          }}
                        </div>
                        <div class="theNumberOfLibraries normalOpt">
                          {{ $fanyi("已提出数") }}:{{
                            order_detailitem.send_success_num
                          }}
                        </div>
                      </div>
                      <div
                        class="numRow"
                        v-if="
                          order_detailitem.option &&
                          order_detailitem.option.find((item) => {
                            return item.checked == true && item.num > 0;
                          })
                        "
                      >
                        <div
                          class="normalOpt optionBox"
                          @click.stop="openOptionBox(order_detailitem.option)"
                        >
                          <span
                            class="optionOpt"
                            v-for="(
                              order_detailoptionitem, order_detailoptionindex
                            ) in order_detailitem.option"
                            :key="order_detailoptionindex"
                          >
                            <span class="tagNum">
                              {{ order_detailoptionitem.sort + 1 }}</span
                            >
                            *{{ order_detailoptionitem.num }}，
                          </span>
                          <img
                            class="optionIcon"
                            :src="require('@/assets/icon/xiatubiao.png')"
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        class="numRow"
                        v-for="(
                          tagItem, tagIndex
                        ) in order_detailitem.order_detail_tag"
                        :key="tagIndex"
                      >
                        <div
                          class="productTag"
                          @click="
                            openProductTagEditorPopup(
                              order_detailitem,
                              tagIndex
                            )
                          "
                        >
                          <img
                            :src="require('@/assets/icon/xiatubiao.png')"
                            alt=""
                          />
                          <p
                            class="placeFont"
                            v-if="
                              !order_detailitem.order_detail_tag ||
                              !order_detailitem.order_detail_tag.length
                            "
                          >
                            {{ $fanyi("选择标签信息") }}
                          </p>
                          <template v-else>
                            <div>
                              <div class="u-line">
                                {{ $fanyi("类别") }}：{{
                                  tagItem.type_translate
                                }}
                              </div>
                              <div class="u-line">
                                {{ $fanyi("标签号") }}：{{ tagItem.no }}
                              </div>
                              <div class="u-line">
                                {{ $fanyi("商品码") }}：{{ tagItem.goods_no }}
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="numRow">
                        <div
                          class="productTag"
                          @click="openProductTagEditorPopup(order_detailitem)"
                        >
                          <img
                            class="addIconimg"
                            :src="require('@/assets/icon/addIcon.png')"
                            alt=""
                          />
                          <p class="placeFont">
                            {{ $fanyi("选择标签信息") }}
                          </p>
                        </div>
                      </div>
                      <textarea
                        v-autoHeightForLineBreak
                        class="remarkBox"
                        v-model="order_detailitem.client_remark"
                        @change="
                          wmsupdateClientRemark(
                            order_detailitem.id,
                            order_detailitem.client_remark
                          )
                        "
                      ></textarea>
                    </div>
                  </li>
                </ul>
                <nodata v-if="!gratisTable.length && !loading" />
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
        <!-- 付费仓库列表 -->
        <van-tab
          :title="
            $fanyi('收费仓库') + `(${findNum(stockTableNum, '收费仓库')})`
          "
        >
          <div class="tablePayBg">
            <van-pull-refresh
              v-model="payTablerefreshing"
              @refresh="onRefresh"
              head-height="100"
              :loosing-text="$fanyi('释放即可刷新...')"
              :pulling-text="$fanyi('下拉刷新')"
              :loading-text="$fanyi('加载中')"
              :success-text="$fanyi('加载成功')"
            >
              <van-list
                v-model:loading="loading"
                :loading-text="$fanyi('加载中')"
                :finished="payTablefinished"
                :finished-text="$fanyi('')"
                @load="onLoad"
              >
                <div
                  class="tablePayBox"
                  v-for="(payTableItem, payTableIndex) in payTable"
                  :key="payTableIndex"
                >
                  <h2>
                    <div class="boxNumber">
                      {{ $fanyi("箱号") }}：{{ payTableItem.box_no }}
                    </div>
                    <div class="linkIcon" @click.stop="gonolist(payTableItem)">
                      <van-icon name="arrow" />
                    </div>
                  </h2>
                  <div class="parameter">
                    <div class="parameterOpt">
                      <span class="otitle"> {{ $fanyi("体积") }}： </span>
                      <span class="onum">
                        {{
                          $fun.ceil(
                            (payTableItem.box_c *
                              payTableItem.box_k *
                              payTableItem.box_g) /
                              1000000
                          )
                        }}m³</span
                      >
                    </div>
                    <div class="parameterOpt">
                      <span class="otitle"> {{ $fanyi("实重") }}： </span>
                      <span class="onum"> {{ payTableItem.box_weight }}KG</span>
                    </div>
                    <div class="parameterOpt">
                      <span class="otitle"> {{ $fanyi("已产生费用") }}： </span>
                      <span class="onum redFont"
                        >{{ payTableItem.amount }}{{ $fanyi("元") }}</span
                      >
                    </div>
                    <div class="parameterOpt">
                      <span class="otitle"> {{ $fanyi("收费天数") }}： </span>
                      <span class="onum"
                        >{{ payTableItem.amount_day }}{{ $fanyi("天") }}</span
                      >
                    </div>
                  </div>
                </div>
                <nodata v-if="!payTable.length && !loading" />
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
        <!-- 装箱中列表 -->
        <!-- is_encasement_authorized=1是授权客户 -->
        <van-tab
          v-if="$store.state.userInfo.is_encasement_authorized"
          :title="
            $fanyi('货物装箱中') + `(${findNum(stockTableNum, '装箱中')})`
          "
        >
          <div class="tablePosiBox">
            <van-pull-refresh
              v-model="inPackingTablerefreshing"
              @refresh="onRefresh"
              head-height="100"
              :loosing-text="$fanyi('释放即可刷新...')"
              :pulling-text="$fanyi('下拉刷新')"
              :loading-text="$fanyi('加载中')"
              :success-text="$fanyi('加载成功')"
            >
              <van-list
                v-model:loading="loading"
                :loading-text="$fanyi('加载中')"
                :finished="inPackingTablefinished"
                :finished-text="$fanyi('')"
                @load="onLoad"
              >
                <ul class="gratisTableBox">
                  <li
                    v-for="(inPackingItem, inPackingIndex) in inPackingTable"
                    :key="inPackingIndex"
                    :class="{
                      openrowd: inPackingItem.Collapse,
                    }"
                  >
                    <h2
                      @click="inPackingItem.Collapse = !inPackingItem.Collapse"
                    >
                      <span
                        v-longpress="
                          () => {
                            copySn(inPackingItem.order_sn);
                          }
                        "
                        >{{ $fanyi("订单号") }}：{{
                          inPackingItem.order_sn
                        }}</span
                      >
                      <van-icon
                        name="arrow-down"
                        :class="{ Collapse: inPackingItem.Collapse }"
                      />
                    </h2>
                    <div
                      class="goodsList"
                      v-for="(
                        order_detailitem, order_detailindex
                      ) in inPackingItem.order_detail"
                      :key="order_detailindex"
                    >
                      <div class="outfit">
                        <span>番号：#{{ order_detailitem.sorting }}</span>
                      </div>
                      <div
                        class="productInformation"
                        @click="
                          $fun.toCommodityDetails(
                            order_detailitem.goods_id,
                            order_detailitem.from_platform
                          )
                        "
                      >
                        <van-image
                          lazy-load
                          class="productImg"
                          :src="order_detailitem.pic"
                        >
                          <template v-slot:loading>
                            <van-loading type="spinner" size="20" />
                          </template>
                        </van-image>
                        <div class="infoBox">
                          <h3>{{ order_detailitem.goods_title }}</h3>
                          <div
                            class="detailBox"
                            @click.stop="openDetailBox(order_detailitem)"
                          >
                            <span
                              v-for="(
                                order_detaildetailitem, order_detaildetailindex
                              ) in order_detailitem.detail"
                              :key="order_detaildetailindex"
                            >
                              {{ order_detaildetailitem.value }};
                            </span>
                          </div>
                          <div class="priceBox">
                            <span class="redFont">
                              <span class="bigFont">
                                {{ $fun.ceil(order_detailitem.confirm_price) }}
                                {{ $fanyi("元") }}
                              </span>

                              <span class="smallFont">
                                ({{
                                  $fun.JPYNumSegmentation(
                                    $fun.roundNumber(
                                      order_detailitem.confirm_price *
                                        $store.state.exchangeRate
                                    )
                                  )
                                }}
                                円 )
                              </span>
                            </span>
                            <span class="grayFont"
                              >X {{ order_detailitem.confirm_num }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="numRow">
                        <div class="normalOpt greenOpt">
                          {{ $fanyi("装箱数量") }}:{{
                            order_detailitem.packing_quantity
                          }}
                        </div>
                      </div>
                      <div
                        class="labelBox"
                        v-if="
                          order_detailitem.order_detail_tag &&
                          order_detailitem.order_detail_tag.length
                        "
                      >
                        <div
                          class="tagList"
                          v-for="(
                            tagItem, tagIndex
                          ) in order_detailitem.order_detail_tag"
                          :key="tagIndex"
                        >
                          <div class="u-line">
                            {{ $fanyi("类别") }}：{{ tagItem.type_translate }}
                          </div>
                          <div class="u-line">
                            {{ $fanyi("商品码") }}：{{ tagItem.goods_no }}
                          </div>
                          <div class="u-line">
                            {{ $fanyi("标签号") }}：{{ tagItem.no }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="numRow"
                        v-if="
                          order_detailitem.option &&
                          order_detailitem.option.find((item) => {
                            return item.checked == true && item.num > 0;
                          })
                        "
                      >
                        <div
                          class="normalOpt optionBox"
                          @click.stop="openOptionBox(order_detailitem.option)"
                        >
                          <span
                            class="optionOpt"
                            v-for="(
                              order_detailoptionitem, order_detailoptionindex
                            ) in order_detailitem.option"
                            :key="order_detailoptionindex"
                          >
                            <span class="tagNum">
                              {{ order_detailoptionitem.sort + 1 }}</span
                            >
                            *{{ order_detailoptionitem.num }}，
                          </span>
                          <img
                            class="optionIcon"
                            :src="require('@/assets/icon/xiatubiao.png')"
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        class="remarkBox"
                        v-if="order_detailitem.client_remark"
                      >
                        {{ order_detailitem.client_remark }}
                      </div>
                    </div>
                  </li>
                </ul>
                <nodata v-if="!inPackingTable.length && !loading" />
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
      </van-tabs>

      <detailsAllShowVue ref="detailsAllShowVueRef" />
      <optionsAllShowVue ref="optionsAllShowVueRef" />
    </div>
    <div class="footBarBox">
      <template v-if="swiperModel == 0">
        <input type="checkbox" :checked="checked" @click.stop="allChoice()" />
        <span class="quanxuan" @click.stop="allChoice()">{{
          $fanyi("全选")
        }}</span>
        <span>{{ $fanyi("sku") }}:</span>
        <span class="num">{{ commodityTotalData.fanshu }}</span>
        <!-- 2023/8/1 不限制授权客户不能自己提出配送单-->
        <!-- v-if="!$store.state.userInfo.is_encasement_authorized" -->
        <button @click.stop="deliverOrder">
          {{ $fanyi("提出配送单") }}
        </button>
      </template>

      <template v-else-if="swiperModel == 1">
        <div class="shippingTips">
          <img :src="require('@/assets/icon/redTiShi.png')" alt="" />
          <span>
            {{ $fanyi("收费仓库里货物需要发货，请直接与您的负责人联系！") }}
          </span>
        </div>
      </template>
      <template v-else-if="swiperModel == 2">
        <div class="shippingTips">
          <img :src="require('@/assets/icon/redTiShi.png')" alt="" />
          <span>
            {{ $fanyi("紧急情况下可以联系负责人，应对发送。") }}
          </span>
        </div>
      </template>
    </div>
  </div>
  <!-- 箱号列表 -->
  <div class="whoseHousPage" v-if="showNolist == true">
    <div>
      <headBackBox iCustomizdde="true" :icon="true">
        <template v-slot:left-icon>
          <van-icon name="arrow-left" @click="showNolist = false" />
        </template>
        <template #default>
          <span> {{ $fanyi("箱号") }}{{ Nolist.box_no }} </span>
        </template>
      </headBackBox>
      <div class="tablePosiBox">
        <ul class="gratisTableBox">
          <li
            v-for="(gratisItem, gratisIndex) in Nolist.order"
            :key="gratisIndex"
            :class="{
              openrowd: gratisItem.Collapse,
            }"
          >
            <h2 @click="gratisItem.Collapse = !gratisItem.Collapse">
              <span
                v-longpress="
                  () => {
                    copySn(gratisItem.order_sn);
                  }
                "
                >{{ $fanyi("订单号") }}：{{ gratisItem.order_sn }}</span
              >
              <van-icon
                name="arrow-down"
                :class="{ Collapse: gratisItem.Collapse }"
              />
            </h2>
            <div
              class="goodsList"
              v-for="(
                order_detailitem, order_detailindex
              ) in gratisItem.order_detail"
              :key="order_detailindex"
            >
              <div class="outfit">
                <span>番号：#{{ gratisItem.sorting }}</span>
              </div>
              <div class="productInformation">
                <van-image
                  lazy-load
                  class="productImg"
                  :src="order_detailitem.pic"
                  @click="
                    $fun.toCommodityDetails(
                      gratisItem.goods_id,
                      gratisItem.from_platform
                    )
                  "
                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <div class="infoBox">
                  <h3>{{ order_detailitem.goods_title }}</h3>
                  <div
                    class="detailBox"
                    @click.stop="openDetailBox(order_detailitem)"
                  >
                    <span
                      v-for="(
                        order_detaildetailitem, order_detaildetailindex
                      ) in order_detailitem.detail"
                      :key="order_detaildetailindex"
                    >
                      {{ order_detaildetailitem.value }};
                    </span>
                  </div>
                  <div class="priceBox">
                    <span class="redFont">
                      <span class="bigFont">
                        {{ $fun.ceil(order_detailitem.confirm_price) }}
                        {{ $fanyi("元") }}
                      </span>

                      <span class="smallFont">
                        ({{
                          $fun.JPYNumSegmentation(
                            $fun.roundNumber(
                              order_detailitem.confirm_price *
                                $store.state.exchangeRate
                            )
                          )
                        }}
                        円 )
                      </span>
                    </span>
                    <span class="grayFont"
                      >X {{ order_detailitem.confirm_num }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="numRow"></div>
              <div class="numRow">
                <div class="normalOpt greenOpt">
                  {{ $fanyi("装箱数") }}:{{ order_detailitem.is_box_num }}
                </div>
              </div>
              <div
                class="numRow"
                v-if="
                  order_detailitem.option &&
                  order_detailitem.option.find((item) => {
                    return item.checked == true && item.num > 0;
                  })
                "
              >
                <div
                  class="normalOpt optionBox"
                  @click.stop="openOptionBox(order_detailitem.option)"
                >
                  <span
                    class="optionOpt"
                    v-for="(
                      order_detailoptionitem, order_detailoptionindex
                    ) in order_detailitem.option"
                    :key="order_detailoptionindex"
                  >
                    <span class="tagNum">
                      {{ order_detailoptionitem.sort + 1 }}</span
                    >
                    *{{ order_detailoptionitem.num }}，
                  </span>
                </div>
              </div>
              <div class="remarkBox" v-if="order_detailitem.client_remark">
                {{ order_detailitem.client_remark }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <detailsAllShowVue ref="detailsAllShowVueRef" />
    <optionsAllShowVue ref="optionsAllShowVueRef" />
  </div>
  <editProductTag ref="editTagRef" />
</template>
<script>
import editProductTag from "./components/editProductTag.vue";
import nodata from "./components/nodata.vue";
import { ref, getCurrentInstance, onMounted, toRef, watch } from "vue";
import { SwipeInstance } from "vant";
import headBackBox from "../../components/headGoBack";
import tabToggleVue from "../../components/tabToggle";
import detailsAllShowVue from "../../components/detailsAllShow";
import { Toast } from "vant";
import optionsAllShowVue from "../../components/optionsAllShow";
import { router } from "../../router";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();

    // 根据路由切换仓库
    const type = ref("stockAutoList");
    const showNolist = ref(false); // 为true显示箱号列表
    const Nolist = ref({}); // 箱号列表
    const loading = ref(false); //加载laoding
    const pageSize = ref(20);
    // 免费仓库数据
    const gratisTable = ref([]);
    const gratisTablefinished = ref(false);
    const gratisTablepage = ref(0);
    const gratisTablerefreshing = ref(false);

    // 付费仓库数据
    const payTable = ref([]);
    const payTablefinished = ref(false);
    const payTablerefreshing = ref(false);
    const payTablepage = ref(0);

    // 装箱中数据
    const inPackingTable = ref([]);
    const inPackingTablefinished = ref(false);
    const inPackingTablerefreshing = ref(false);
    const inPackingTablepage = ref(0);

    // 免费仓库是否全选
    const checked = ref(false);
    const swiperModel = ref(0);
    const detailsAllShowVueRef = ref(null);
    const optionsAllShowVueRef = ref(null);
    const swipeRef = ref(null);
    const stockTableNum = ref([]);
    const navList = ref([
      {
        label: "普通仓库",
        value: "0",
      },
      {
        label: "收费仓库",
        value: "1",
      },
    ]);
    if (proxy.$route.query.type) {
      switch (proxy.$route.query.type) {
        case "gratis":
          swiperModel.value = 0;
          break;
        case "pay":
          swiperModel.value = 1;
          break;
        case "inPacking":
          swiperModel.value = 2;
          break;
        default:
          break;
      }
    }
    // watch(
    //   () => swiperModel.value,
    //   (val, preVal) => {
    //     //val为修改后的值,preVal为修改前的值
    //     // 初始化数据
    //     // swipeRef.value?.next();
    //     proxy.toModel(val);
    //   },
    //   {
    //     //如果加了这个参数，值为true的话，就消除了惰性，watch会在创建后立即执行一次
    //     //那么首次执行，val为默认值,preVal为undefined
    //     // immediate: true,
    //     // //这个参数代表监听对象时，可以监听深度嵌套的对象属性
    //     // //比如message是一个对象的话，可以监听到message.a.b.c，也就是message下的所有属性
    //     // deep: true,
    //   }
    // );
    // 从获取的数据列表中查找出显示的数字
    const findNum = (list, name) => {
      let num = 0;
      let jieguo = list.find((item) => {
        return item.name == name;
      });
      if (!!jieguo) {
        num = jieguo.count;
      }
      return num;
    };
    // 获取头部数字
    const getstockTableNum = () => {
      proxy.$api.stockTableNum().then((res) => {
        ////console.log('事件名',res)
        if (res.code != 0) return;
        //接下来的操作
        stockTableNum.value = res.data;
      });
    };
    getstockTableNum();
    const commodityTotalData = ref({
      // 已勾选番数
      fanshu: 0,
      // 已勾选商品id数组
      selectedProductId: [],
    });
    // 统计各种数据
    const commodityTotal = () => {
      commodityTotalData.value = {
        fanshu: 0,
        selectedProductId: [],
      };
      gratisTable.value.forEach((item, index) => {
        item.order_detail.forEach((detailItem) => {
          // detailItem-单个商品
          if (detailItem.checked) {
            commodityTotalData.value.fanshu += 1;
            commodityTotalData.value.selectedProductId.push(detailItem.id);
          }
        });
      });
    };

    const onLoad = () => {
      let page = 0;
      if (swiperModel.value == 0) {
        // 进行判断如果数据加载完了，下拉升刷新不执行
        if (gratisTablefinished.value == true) {
          gratisTablerefreshing.value = false;
          Toast(proxy.$fanyi("没有更多了"));
          return;
        }
        if (gratisTable.length == 0) {
          gratisTablepage.value = 1;
        } else {
          gratisTablepage.value += 1;
        }
        page = gratisTablepage.value;
        // 获取免费仓库数据
        proxy.$api
          .stockAutoList({
            page: page,
            pageSize: pageSize.value,
          })
          .then((res) => {
            if (res.code != 0) return;

            // 判断是下刷新海是触底
            if (gratisTablerefreshing.value == true) {
              gratisTable.value = [...res.data, ...gratisTable.value];
            } else {
              gratisTable.value = [...gratisTable.value, ...res.data];
            }
            loading.value = false;
            gratisTablerefreshing.value = false;
            // 进行判断如果数据记载完成没有数据底部显示提示信息
            // if ( gratisTable.value.length == res.total || res.data?.data.length < pageSize.value) {

            // }
            gratisTablefinished.value = true;
          });
      }
      if (swiperModel.value == 1) {
        // 进行判断如果数据加载完了，下拉升刷新不执行
        if (payTablefinished.value == true) {
          payTablerefreshing.value = false;
          Toast(proxy.$fanyi("没有更多了"));
          return;
        }
        if (payTable.length == 0) {
          payTablepage.value = 1;
        } else {
          payTablepage.value += 1;
        }
        page = payTablepage.value;
        // 获取付费仓库数据
        proxy.$api
          .stockFeeList({
            page: page,
            pageSize: pageSize.value,
          })
          .then((res) => {
            if (res.code != 0) return;

            // 判断是下刷新海是触底
            if (payTablerefreshing.value == true) {
              payTable.value = [...res.data, ...payTable.value];
            } else {
              payTable.value = [...payTable.value, ...res.data];
            }
            loading.value = false;
            payTablerefreshing.value = false;
            // 进行判断如果数据记载完成没有数据底部显示提示信息
            // if ( gratisTable.value.length == res.total || res.data?.data.length < pageSize.value) {

            // }
            payTablefinished.value = true;
            // payTable.value = res.data;
          });
      }
      if (swiperModel.value == 2) {
        // 进行判断如果数据加载完了，下拉升刷新不执行
        if (inPackingTablefinished.value == true) {
          inPackingTablerefreshing.value = false;
          Toast(proxy.$fanyi("没有更多了"));
          return;
        }
        if (inPackingTable.length == 0) {
          inPackingTablepage.value = 1;
        } else {
          inPackingTablepage.value += 1;
        }
        page = inPackingTablepage.value;
        // 获取付费仓库数据
        proxy.$api
          .encasementList({
            page: page,
            pageSize: pageSize.value,
          })
          .then((res) => {
            if (res.code != 0) return;
            // 判断是下刷新海是触底
            if (inPackingTablerefreshing.value == true) {
              inPackingTable.value = [...res.data, ...inPackingTable.value];
            } else {
              inPackingTable.value = [...inPackingTable.value, ...res.data];
            }
            loading.value = false;
            inPackingTablerefreshing.value = false;
            // 进行判断如果数据记载完成没有数据底部显示提示信息
            // if ( gratisTable.value.length == res.total || res.data?.data.length < pageSize.value) {

            // }
            inPackingTablefinished.value = true;
            // payTable.value = res.data;
          });
      }
    };

    // 免费仓库单选
    const oddChoice = (goodsItem, i) => {
      if (!goodsItem.send_await_num) {
        goodsItem.checked = false;
        proxy.$message.primary(
          proxy.$fanyi(
            "部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"
          )
        );
      }
      let flag = gratisTable.value[i].order_detail.every((item) => {
        return item.checked == true;
      });
      if (flag) {
        gratisTable.value[i].checked = true;
      } else {
        gratisTable.value[i].checked = false;
      }
      isCheckedAll();
      commodityTotal();
      //   flag ? (gratisTable.value[i].checked = true) : (gratisTable.value[i].checked = false);
    };
    // 免费仓库订单全选
    const shopAll = (i) => {
      if (gratisTable.value[i].checked) {
        gratisTable.value[i].order_detail.forEach((item) => {
          if (!item.send_await_num) {
            item.checked = false;
            proxy.$message.primary(
              proxy.$fanyi(
                "部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"
              )
            );
            gratisTable.value[i].checked = false;
          } else {
            item.checked = true;
          }
        });
      } else {
        gratisTable.value[i].order_detail.forEach((item) => {
          item.checked = false;
        });
      }
      isCheckedAll();
      commodityTotal();
    };
    // 免费仓库全选
    const allChoice = () => {
      checked.value = !checked.value;
      if (checked.value) {
        gratisTable.value.forEach((item, index) => {
          item.checked = true;
          shopAll(index);
        });
      } else {
        gratisTable.value.forEach((item, index) => {
          item.checked = false;
          shopAll(index);
        });
      }
      commodityTotal();
    };
    // 免费仓库是否全选中
    const isCheckedAll = () => {
      // gratisTable.value.forEach((gratisTableItem) => {
      // gratisTableItem-单条订单
      //   gratisTableItem.order_detail.forEach((goodsItem) => {
      //     // goodsItem-单个商品
      //     // if (!goodsItem.send_await_num) {
      //     //   goodsItem.checked = false;
      //     //   proxy.$message.primary(
      //     //     proxy.$fanyi(
      //     //       "部分商品已打包装箱，不可手动提出，请联系您的负责人进行了解"
      //     //     )
      //     //   );
      //     // }
      //   });
      //   gratisTableItem.checked = gratisTableItem.order_detail.every(
      //     (item, index) => item.checked == true
      //   );
      // });
      var flag = gratisTable.value.every((item, index) => item.checked == true);
      if (flag == true) {
        checked.value = true;
      } else {
        checked.value = false;
      }
    };
    // 类型切换事件
    const clicktabs = (val) => {
      console.log(val);
      swiperModel.value = val.name;
    };
    // 下拉触发
    const onRefresh = () => {
      onLoad();
    };
    // 点击到箱号列表页面
    const gonolist = (item) => {
      Nolist.value = item;
      showNolist.value = true;
    };
    // 打开商品详情弹窗
    const openDetailBox = (data) => {
      detailsAllShowVueRef.value.open({
        goods_title: data.goods_title,
        detail: data.detail,
        pic: data.pic,
      });
    };
    // 打开附加服务弹窗
    const openOptionBox = (data) => {
      optionsAllShowVueRef.value.open(data);
    };
    // 提出配送单
    const deliverOrder = () => {
      let idsStr = commodityTotalData.value.selectedProductId.join(",");
      if (idsStr) {
        proxy.$fun.routerToPage(`/deliveryDetails?id=${idsStr}&type=temporary`);
      } else {
        proxy.$message.warning(proxy.$fanyi("请选择商品"));
      }
    };
    // 修改备注
    const wmsupdateClientRemark = (id, remark) => {
      proxy.$api
        .wmsupdateClientRemark({ order_detail_id: id, client_remark: remark })
        .then((res) => {});
    };
    // 打开商品标签编辑弹窗
    const openProductTagEditorPopup = (data, tagIndex) => {
      proxy.$refs.editTagRef.open(data, tagIndex);
    };
    // 复制单号
    const copySn = (order_sn) => {
      navigator.clipboard.writeText(order_sn);
      proxy.$message.primary(proxy.$fanyi("复制成功"));
    };
    return {
      type,
      gratisTable,
      wmsupdateClientRemark,
      openProductTagEditorPopup,
      checked,
      detailsAllShowVueRef,
      optionsAllShowVueRef,
      commodityTotalData,
      swiperModel,
      gratisTablefinished,
      gratisTablerefreshing,
      payTable,
      payTablepage,
      payTablerefreshing,
      payTablefinished,
      inPackingTable,
      inPackingTablepage,
      inPackingTablerefreshing,
      inPackingTablefinished,
      gratisTablepage,
      navList,
      loading,
      clicktabs,
      pageSize,
      showNolist,
      Nolist,
      gonolist,
      openDetailBox,
      openOptionBox,
      oddChoice,
      onRefresh,
      shopAll,
      onLoad,
      stockTableNum,
      allChoice,
      findNum,
      isCheckedAll,
      commodityTotal,
      deliverOrder,
      copySn,
    };
  },
  methods: {
    // toModel(value) {
    //   this.$refs.swipeRef.swipeTo(
    //     this.navList.findIndex((item) => {
    //       return item.value == value;
    //     })
    //   );
    // },
  },
  components: {
    headBackBox,
    tabToggleVue,
    detailsAllShowVue,
    optionsAllShowVue,
    editProductTag,
    nodata,
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.whoseHousPage {
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

:deep() .van-tab__text {
  font-size: 28px;

  font-weight: 500;
}

:deep() .van-tabs__line {
  background: #b4272b;
}

:deep().van-tabs__wrap {
  height: 68px;
  position: sticky;
  top: 100px;
  z-index: 999;
}

:deep().van-tabs__wrap {
  height: 68px;
}

input[type="checkbox"] {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin: 0;
  margin-top: 4px;
  margin-right: 20px;
  border-color: #dfdfdf;
}

.tablePosiBox {
  min-height: 70vh;

  position: relative;

  .gratisTableBox {
    margin: 0 auto;
    width: 690px;

    li {
      margin: 20px 0 1px;
      padding: 0 30px;
      background-color: white;
      max-height: 100px;
      overflow: hidden;
      border-radius: 6px;

      &.openrowd {
        max-height: unset;
      }

      h2 {
        height: 100px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .van-icon-arrow-down {
          margin-left: auto;
          transition: 0.3s;
          font-size: 30px;
        }

        .van-icon-arrow-down.Collapse {
          rotate: -180deg;
        }

        span {
          font-size: 28px;
          font-weight: 500;
        }
      }

      // 订单内商品
      .goodsList {
        padding-bottom: 1px;
        margin-bottom: 30px;
        border-bottom: dashed 1px #dfdfdf;

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }

        .outfit {
          display: flex;
          align-items: center;
          height: 42px;

          margin-bottom: 20px;

          span {
            font-size: 28px;
          }
        }

        .productInformation {
          display: flex;
          margin-bottom: 30px;

          .productImg {
            $size: 180px;
            flex: 0 0 $size;
            width: $size;
            height: $size;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 20px;
          }

          .infoBox {
            h3 {
              font-size: 28px;
              line-height: 42px;
              margin-bottom: 5px;
              display: flex;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .detailBox {
              width: 350px;
              height: 42px;
              line-height: 39px;
              background: #fafafa;
              border: 1px solid #dfdfdf;
              border-radius: 6px;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 10px;
              margin-bottom: 10px;
              display: flex;

              span {
                display: flex;
                font-size: 22px;
                color: #999999;
              }
            }

            .priceBox {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .redFont {
                display: flex;
                color: #b4272b;
              }

              .bigFont {
                font-size: 28px;
                font-weight: 600;
              }

              .smallFont {
                font-size: 24px;
              }

              .grayFont {
                display: flex;
                font-size: 20px;
                color: #999999;
              }
            }
          }
        }

        .numRow {
          display: flex;
          margin: 20px 0;

          * {
            font-size: 22px;
          }

          .normalOpt {
            padding: 0 18px;
            flex: 1;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
          }

          .greenOpt {
            border-color: #50c136;
            color: #50c136;
            background-color: #f0ffec;
          }

          .optionBox {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            position: relative;

            .optionOpt {
              line-height: 56px;
              .tagNum {
                border: 1px solid #000000;
                border-radius: 50%;
                $size: 22px;
                width: $size;
                height: $size;
                display: inline-block;
                text-align: center;
                line-height: 18px;
              }
            }
            .optionIcon {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 10px;
              width: 12px;
              height: 7px;
            }
          }

          .theNumberOfLibraries {
            flex: 0 0 300px;

            &:first-child {
              margin-right: auto;
            }
          }
        }
        .labelBox {
          min-height: 56px;
          padding: 15px;
          background: #fafafa;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 22px;
          margin-bottom: 30px;
        }
        .productTag {
          width: 630px;
          min-height: 56px;
          background: #fafafa;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          position: relative;
          display: flex;
          align-items: center;
          padding: 12px 20px;
          img {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            width: 12px;
            height: 7px;
          }
          .addIconimg {
            width: 20px;
            height: 20px;
          }
          p {
            font-size: 22px;
            line-height: 30px;
            font-weight: 400;
            color: #000000;
            width: 630-40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
          }
          .placeFont {
            color: #999999;
          }
        }
        .remarkBox {
          width: 100%;
          min-height: 56px;
          padding: 15px;
          background: #fafafa;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 22px;
          line-height: 1.2;
          margin-bottom: 30px;
          resize: none;
        }
      }
    }
  }
}

.tablePayBg {
  min-height: 70vh;
}

.tablePayBox {
  width: 690px;
  // height: 232px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  margin: 20px auto;

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .boxNumber {
      width: 160px;
      height: 60px;
      background: #f0ffec;
      border: 1px solid #50c136;
      border-radius: 6px;
      color: #50c136;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .linkIcon {
      transform: translateX(30px);
      padding: 0 29px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      :deep(.van-icon-arrow) {
        font-size: 25px;
      }
    }
  }

  .parameter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .parameterOpt {
      margin-bottom: 20px;

      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      flex: 0 0 300px;
      line-height: 36px;

      span {
        font-size: 24px;
      }

      .otitle {
        color: #999999;
      }

      .onum {
        color: #000000;
      }

      .redFont {
        color: #b4272b;
      }
    }
  }
}

.footBarBox {
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  height: 108px;
  background-color: white;
  bottom: 0;
  padding: 15px 30px;

  * {
    font-size: 24px;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }

  .quanxuan {
    margin-right: 30px;
  }

  .num {
    font-size: 28px;
    color: #b4272b;
  }

  button {
    margin-left: auto;
    width: 240px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    color: #ffffff;
    font-size: 24px;
  }
}

.shippingTips {
  margin: 20px auto;
  width: 690px;
  height: 68px;
  background: #ffe9e9;
  border-radius: 6px;
  padding: 16px 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 11px;
    $size: 30px;
    width: $size;
    height: $size;
  }

  span {
    color: #b4272b;
    font-size: 24px;
  }
}
</style>
